import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/mdi_facebook.svg";
import XLogo from "../images/Vector (13).svg";
import InstaLogo from "../images/ri_instagram-fill.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";
import SocietyContactForm from "../components/societyContactForm";

const SocietyPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "society-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "overview-11" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "Tanishq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "Gallery Course 1 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2: wpMediaItem(title: { eq: "Gallery Course 1 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3: wpMediaItem(title: { eq: "Gallery Mix 1 3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "Gallery Mix 2 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "Gallery Mix 2 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "Gallery Course 3 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "Gallery Mix 3 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Society Enquiries",
				item: {
					url: `${siteUrl}/society-enquiries`,
					id: `${siteUrl}/society-enquiries`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Society Enquiries | Toot Hill Golf Course"
				description="If your society wishes to use our course leave your details to begin your booking process."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/society-enquiries`,
					title: "Society Enquiries | Toot Hill Golf Course",
					description:
						"If your society wishes to use our course leave your details to begin your booking process.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HeroOther
						smallHeading="Talk to us"
						title="Society enquiries"
						description={
							<>
								If your society wishes to use our course leave your details
								<br className="d-none d-md-block" /> to begin your booking
								process.
							</>
						}
						button1Text="Call us"
						button1Link="tel:01277365523"
						button2Text="Submit enquiry"
						button2Link="/society-enquiries#society-contact-form"
						backgroundImage={
							data.heroImg.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="py-5 py-lg-7 bg-primary">
						<Container>
							<Row className="justify-content-center">
								<Col className="text-center" lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										How it works
									</p>
									<h2 className="line-heading text-white mb-4 fs-1">
										A welcoming course
									</h2>
									<p className="text-white text-center">
										At Toot Hill we pride ourselves on providing excellent
										facilities for societies to enjoy the game we all love. We
										also have two great spaces to socialise in after the golf is
										finished, our relaxed Pantry and the more adventurous
										Tanishq.
									</p>
									<p className="text-white text-center">
										Societies can pre book a hearty breakfast from The Pantry to
										make sure they are well fuelled before stepping out onto the
										course
									</p>
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										target="_blank"
										rel="noreferrer"
										href="https://toot-hill-golf.rjmdigital.net/wp-content/uploads/2024/02/The-Pantry-Society-menu-updated.pdf"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
									>
										Society menu
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container>
							<Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0 py-4">
								<Col lg={6} xl={5}>
									<div id="society-contact-form">
										<SocietyContactForm />
									</div>
								</Col>
								<Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
									<div
										className="h-100"
										style={{ borderRadius: "8px", overflow: "hidden" }}
									>
										<GatsbyImage
											style={{ borderRadius: "8px", height: "700px" }}
											className="w-100 "
											image={
												data.Img1.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.Img1.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default SocietyPage;
